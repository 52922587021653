/** @format */

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/ContextWrapper";
import Layout from "../../components/Layout";
import { emailValidator } from "../../utils/validaciones";
import { Button, Form } from "react-bootstrap";
import { navigate } from "gatsby-link";

function Perfil() {
  const [loading, setLoading] = useState(false);
  const [validacion, setValidacion] = useState("");
  const [registroExitoso, setRegistroExitoso] = useState(false);

  const context = useContext(AppContext);

  const [errores, setErrores] = useState({
    usuario: "",
    nombre: "",
    apellido: "",
    direccion: "",
    telefono: "",
    email: "",
    fechaNacimiento: "",
  });

  const [registro, setRegistro] = useState({
    usuario: "",
    nombre: "",
    apellido: "",
    direccion: "",
    telefono: "",
    email: "",
    fechaNacimiento: "",
  });

  const handleSubmit = () => {
    let valido = true;
    // se limpian los errores.
    let validacionErrores = {
      usuario: "",
      nombre: "",
      apellido: "",
      direccion: "",
      telefono: "",
      email: "",
      fechaNacimiento: "",
    };

    // se revisa que se han validado las propiedades.
    if (registro.nombre.length === 0) {
      validacionErrores.nombre = "El campo nombre es requerido";
      valido = false;
    }
    if (registro.apellido.length === 0) {
      validacionErrores.apellido = "El campo apellido es requerido";
      valido = false;
    }

    if (registro.direccion.length === 0) {
      validacionErrores.direccion = "El campo de dirección es requerido";
      valido = false;
    }

    if (registro.email.length === 0) {
      validacionErrores.email = "El campo de correo es requerido";
      valido = false;
    }

    if (registro.telefono.length === 0) {
      validacionErrores.telefono = "El campo de teléfono es requerido";
      valido = false;
    }

    setErrores(validacionErrores);

    // si  la validacion es correcta.
    if (valido) {
      setLoading(true);
      setRegistroExitoso(false);
      setValidacion("");
      fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/user/updateInfo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${context.store.usuarioAutenticado.token}`,
          "Content-type": "application/json; charset=UTF-8",
          nombre: registro.nombre,
          apellido: registro.apellido,
          email: registro.email,
          direccion: registro.direccion,
          telefono: registro.telefono,
          usuario: context.store.usuarioAutenticado.username,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            setRegistroExitoso(true);
            setValidacion("Se ha actualizado correctamente tu información");
          } else {
            setValidacion(await response.text());
          }

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setValidacion(
            "No se ha podido realizar la acción, intenta nuevamente o contacta al administrador del sitio."
          );
        });
    }
  };

  // permite obtener los valores de la pantalla.
  const handleChange = (event) => {
    // si el campo es correo electrónico
    if (event.target.name === "email") {
      if (!emailValidator.test(event.target.value))
        setErrores({ ...errores, [event.target.name]: "Ingresa un correo valido. Ej: tucorreo@direccion.com" });
      else setErrores({ ...errores, [event.target.name]: "" });
    }
    // si el capo es teléfono
    else if (event.target.name === "telefono") {
      if (event.target.value.length < 8)
        setErrores({ ...errores, [event.target.name]: "El teléfono debe tener 8 digitos. Ej: 90909999" });
      else setErrores({ ...errores, [event.target.name]: "" });
    } else {
      // para todos los otros campos
      if (event.target.value.length === 0) setErrores({ ...errores, [event.target.name]: "El campo es requerido" });
      else setErrores({ ...errores, [event.target.name]: "" });
    }

    setRegistro({ ...registro, [event.target.name]: event.target.value });
  };

  // se carga la información del usuario conectado
  useEffect(() => {
    setLoading(true);
    fetch(
      `${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/user/info/${context.store.usuarioAutenticado.username}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${context.store.usuarioAutenticado.token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        setRegistro(result);
      })
      .catch((error) => {
        setLoading(false);
      });

    if (!context.store.usuarioAutenticado.username) {
      setTimeout(() => navigate("/"), 1);
      //return <div>No tienes acceso a la pagna</div>;
    }
  }, [context.store.usuarioAutenticado]);

  return (
    <Layout>
      <div className="perfil-container">
        <h3>Tu perfil:</h3>
        <hr />
        <Form>
          <div className="perfil-nombres">
            <Form.Group className="perfil-nombres-controles" controlId="txtNombre">
              <Form.Label>Nombre:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nombre"
                name="nombre"
                value={registro?.nombre}
                onChange={handleChange}
                isInvalid={errores.nombre.length > 0}
              />
              <Form.Control.Feedback type="invalid">{errores.nombre}</Form.Control.Feedback>
              <Form.Text className="text-muted">Aquí puedes actualizar tu nombre</Form.Text>
            </Form.Group>

            <Form.Group className="perfil-nombres-controles" controlId="txtApellido">
              <Form.Label>Apellido:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Apellido"
                name="apellido"
                value={registro?.apellido}
                onChange={handleChange}
                isInvalid={errores.apellido.length > 0}
              />
              <Form.Control.Feedback type="invalid">{errores.apellido}</Form.Control.Feedback>
              <Form.Text className="text-muted">Aquí puedes actualizar tu apellido</Form.Text>
            </Form.Group>
          </div>

          <Form.Group className="perfil-nombres-controles" controlId="txtDireccion">
            <Form.Label>Tu dirección:</Form.Label>
            <Form.Control
              type="text"
              placeholder="direccion"
              name="direccion"
              value={registro?.direccion}
              onChange={handleChange}
              isInvalid={errores.direccion.length > 0}
            />
            <Form.Control.Feedback type="invalid">{errores.direccion}</Form.Control.Feedback>
            <Form.Text className="text-muted">
              Aquí puedes actualizar tu dirección donde enviaremos tus pedidos
            </Form.Text>
          </Form.Group>

          <Form.Group className="perfil-nombres-controles" controlId="txtEmail">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Tu dirección de correo electronico"
              name="email"
              value={registro?.email}
              onChange={handleChange}
              isInvalid={errores.email.length > 0}
            />
            <Form.Control.Feedback type="invalid">{errores.email}</Form.Control.Feedback>
            <Form.Text className="text-muted">
              Aquí puedes actualizar tu dirección de correo para mantenernos en contacto contigo
            </Form.Text>
          </Form.Group>

          <Form.Group className="perfil-nombres-controles" controlId="txtTelefono">
            <Form.Label>Teléfono (+502):</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Tu teléfono"
              name="telefono"
              value={registro?.telefono}
              maxLength={8}
              onChange={handleChange}
              isInvalid={errores.telefono.length > 0}
            />
            <Form.Control.Feedback type="invalid">{errores.telefono}</Form.Control.Feedback>
            <Form.Text className="text-muted">
              Aquí puedes actualizar tu teléfono para mantenernos en contacto contigo
            </Form.Text>
          </Form.Group>
          <div className="d-flex  flex-column justify-content-end">
            <span
              style={registroExitoso ? { color: "green", fontWeight: "bold" } : { color: "red", fontWeight: "bold" }}
            >
              {validacion}
            </span>
            <Button onClick={handleSubmit} className="btn-naranja m-3">
              {loading ? "Actualizando..." : "Actualizar"}
            </Button>
          </div>
        </Form>
      </div>
    </Layout>
  );
}

export default Perfil;
